<template>
  <div id="container">

    <section id="register">

      <div class="wrap">

        <div class="cts_box">
          <h2 class="cts_ttl"><span>初回月謝の支払いを受け付けました</span></h2>
          <div class="thanks_lead">
            <p>
              下記のボタンをおしてログインしてください。
            </p>
          </div>
        </div>

        <div class="cts_box">
          <div class="button_wrap">
            <router-link :to="{ name: 'UsersSignIn'}" class="submit basic arrow">複眼経済塾にログイン</router-link>
          </div>
        </div>

      </div>

      <!--//.wrap-->
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  components: {},
  data() {
    return {
      new_user: null,
    }
  },

  computed: {
    //
  },

  mounted() {
    this.axios
        .post(`${this.env.api_base_url}users/me.json`, {
          one_time_token: this.$route.params.one_time_token,
        })
        .then(response => {
          this.new_user = response.data.user
          //this.saveSession(response.data.user);
        })
        .catch(error => {
          if (error.response.data && error.response.data.error) {
            this.setFlash(error.response.data.error)
            this.$router.push({
              name: 'Home'
            })
          }
        });
  }
}
</script>
